import '@/styles/fonts.css';
import '@/styles/global.css';
import { GatsbyBrowser } from 'gatsby';
import React, { Fragment } from 'react';

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({
  element,
  props,
}) => {
  const Layout = element.type.Layout ?? Fragment;
  return <Layout {...props}>{element}</Layout>;
};
